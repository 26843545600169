<template>
    <div v-if="showForm" class="credit-card-fields" :class="{ 'is-stripe-elements': showStripeElements }">
        <DsInputField
            v-if="showEmailField"
            v-model="email"
            class="fs-block"
            autocomplete="cc-email"
            type="email"
            name="email"
            :label="$t('billing.paymentProcessorCard.email')"
            required
            :submitted="submitted"
            @update:model-value="handleFormInput('email')"
            @blur="handleFormBlur('email')"
        />

        <DsInputField
            v-if="showNameField"
            v-model="nameOnCard"
            class="fs-block"
            autocomplete="cc-name"
            type="text"
            name="nameOnCard"
            :label="$t('MoneyLib.addPaymentModalContent.creditCardName')"
            required
            :submitted="submitted"
            @update:model-value="handleFormInput('nameOnCard')"
            @blur="handleFormBlur('nameOnCard')"
        />

        <RainforestPaymentComponent
            v-if="showRainforest"
            :payment-source="paymentSource"
            :manual-payment="manualPayment"
            class="fs-block"
        />

        <StripeElements
            v-else-if="showStripeElements"
            :readonly="readonly"
            :manual-payment="manualPayment"
            :public-checkout-form="publicCheckoutForm"
            :checkout-form="checkoutForm"
        />

        <template v-else>
            <div class="input-group">
                <DsInputField
                    ref="cardNumberField"
                    v-model="cardNumber"
                    autocomplete="cc-number"
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    name="cardNumber"
                    :label="$t('MoneyLib.addPaymentModalContent.creditCardNumber')"
                    required
                    :invalid="!isCreditCardValid"
                    :maxlength="$options.CREDIT_CARD_NUMBER_MAX_LENGTH"
                    :submitted="submitted"
                    class="fs-block credit-card-field"
                    @keydown="preventAlphanumeric"
                    @update:model-value="handleCardNumberInput"
                    @blur="handleFormBlur('cardNumber')"
                >
                    <template #leading>
                        <img
                            v-if="cardLogo"
                            :src="cardLogo"
                            class="card-logo"
                        />

                        <DsIcon v-else name="credit-card" />
                    </template>
                </DsInputField>

                <DsInputField
                    ref="expirationField"
                    v-model="formattedExpiration"
                    type="text"
                    autocomplete="cc-exp"
                    name="expiration"
                    :label="$t('MoneyLib.addPaymentModalContent.creditCardExpiration')"
                    required
                    :invalid="expiredCardDate"
                    :pattern="$options.CREDIT_CARD_EXPIRATION_REGEX"
                    :maxlength="$options.CREDIT_CARD_EXPIRATION_MAX_LENGTH"
                    :submitted="submitted || expiredCardDate"
                    class="fs-block"
                    @keydown="preventAlphanumeric"
                    @update:model-value="validateExpirationDate"
                    @blur="expirationOnBlur"
                    @focus="expirationOnFocus"
                />

                <div class="cvv">
                    <DsInputField
                        ref="verificationCodeField"
                        v-model="verificationCode"
                        type="text"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        autocomplete="cc-csc"
                        name="verificationCode"
                        :label="$t('MoneyLib.addPaymentModalContent.creditCardCVV')"
                        required
                        :maxlength="verificationMaxLength"
                        :invalid="!isVerificationCodeValid"
                        :submitted="submitted"
                        class="fs-block"
                        @keydown="preventAlphanumeric"
                        @update:model-value="handleVerificationCodeInput"
                        @blur="handleFormBlur('verificationCode')"
                    />
                </div>
            </div>

            <DsMultiselect
                v-model="country"
                searchable
                :placeholder="$t('forms.countryCode')"
                required
                :options="countryOptions"
                tabindex="-1"
                :submitted="submitted"
                class="fs-block"
                @update:model-value="countryChanged"
                @blur="handleFormBlur('country')"
            />

            <DsInputField
                v-if="showFullAddress"
                v-model="address1"
                type="text"
                name="address1"
                autocomplete="address-line1"
                :label="$t('forms.address1')"
                required
                :submitted="submitted"
                class="fs-block"
                @update:model-value="handleFormInput('address1')"
                @blur="handleFormBlur('address1')"
            />

            <div class="input-group">
                <DsInputField
                    v-if="showFullAddress"
                    v-model="locality"
                    type="text"
                    name="locality"
                    autocomplete="address-level2"
                    :label="$t('forms.locality')"
                    required
                    :submitted="submitted"
                    class="fs-block"
                    @update:model-value="handleFormInput('locality')"
                    @blur="handleFormBlur('locality')"
                />

                <DsMultiselect
                    v-if="isRegionRequired"
                    v-model="region"
                    searchable
                    :placeholder="$t('forms.region')"
                    required
                    autocomplete="address-level1"
                    :options="regionOptions"
                    tabindex="-1"
                    :submitted="submitted"
                    class="fs-block"
                    @update:model-value="handleRegionInput"
                    @blur="handleFormBlur('region')"
                />

                <DsInputField
                    v-model="postalCode"
                    type="text"
                    name="postalCode"
                    :label="$t('forms.postalCodeGeneric')"
                    required
                    autocomplete="postal-code"
                    :submitted="submitted"
                    class="fs-block"
                    @update:model-value="handlePostalCodeInput"
                    @blur="handleFormBlur('postalCode')"
                />
            </div>
        </template>

        <i18n-t
            v-if="showChargeStatement && !showRecurringInfo"
            keypath="MoneyLib.addPaymentModalContent.chargeStatement"
            tag="span"
        >
            <template #name>
                <span>{{ chargeStatementData.name }}</span>
            </template>

            <template #amount>
                <span class="primary-text semibold">{{ chargeStatementData.amount }}</span>
            </template>
        </i18n-t>
        <DsCheckbox
            v-if="isAddCreditCardFlow"
            v-model="consentCheck"
            :label="$t('CreditCardFields.explicit')"
            class="explicit-checkbox"
        />
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import luhn from 'luhn';
import moment from 'moment';
import { mapState } from 'vuex';
import {
    DsCheckbox,
    DsIcon,
    DsInputField,
    DsMultiselect,
} from '@infusionsoft/design-system';
import {
    CREDIT_CARD_NUMBER_REGEX,
    CREDIT_CARD_EXPIRATION_REGEX,
    CREDIT_CARD_CVV_REGEX,
    INPUT_DEBOUNCE_DELAY,
} from '@keap-web/shared-ui';

import StripeElements from './StripeElements.vue';
import RainforestPaymentComponent from './RainforestPaymentComponent.vue';
import {
    CREDIT_CARD_EXPIRATION_MAX_LENGTH,
    CREDIT_CARD_NUMBER_MAX_LENGTH,
    PAYMENT_ACCOUNT_TYPE,
} from '../money.constants';
import { detectCardType, getCardLogoPath } from '../money.util';
import useCreditCardFields from './useCreditCardFields';

const YEAR_PLACEHOLDER = 'MM/YY';
const ALLOWED_KEYS = [
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Tab',
    'Delete',
    'Home',
    'End',
];

export default {
    CREDIT_CARD_EXPIRATION_MAX_LENGTH,
    CREDIT_CARD_NUMBER_MAX_LENGTH,
    CREDIT_CARD_EXPIRATION_REGEX,

    components: {
        DsCheckbox,
        DsIcon,
        DsInputField,
        DsMultiselect,
        StripeElements,
        RainforestPaymentComponent,
    },

    props: {
        paymentSource: String,
        manualPayment: [Object, Number],
        publicCheckoutForm: Object,
        checkoutForm: Object,
        readonly: Boolean,
        paymentFrom: String,
        postalCodeFrom: String,
        expirationFrom: String,
        expirationMonthFormat: {
            type: String,
            default: 'MM',
        },
        expirationYearFormat: {
            type: String,
            default: 'YYYY',
        },
        rate: [Number, String],
        showChargeStatement: Boolean,
        showNameField: Boolean,
        showEmailField: Boolean,
        showFullAddress: {
            type: Boolean,
            default: false,
        },
        hasRequiredFields: Boolean,
        loadCountryDispatch: {
            type: String,
            default: 'LOAD_COUNTRY_OPTIONS',
        },
        loadRegionDispatch: {
            type: String,
            default: 'LOAD_REGION_OPTIONS',
        },
        companyProfile: {
            type: Object,
            default () { return {}; },
        },
        countries: {
            type: Array,
            default () { return []; },
        },
        submitted: {
            type: Boolean,
            default: false,
        },
        debounceDelay: {
            type: Number,
            default: INPUT_DEBOUNCE_DELAY,
        },
        showRecurringInfo: Boolean,
        isAddCreditCardFlow: Boolean,
        displayFullName: String,
    },

    emits: ['form-event', 'update:hasRequiredFields'],

    setup() {
        const {
            stripeElementsEnabled,
        } = useCreditCardFields();

        return {
            stripeElementsEnabled,
        };
    },

    data() {
        return {
            email: '',
            nameOnCard: this.paymentFrom,
            cardNumber: '',
            address1: '',
            address2: '',
            country: '',
            locality: '',
            region: '',
            postalCode: this.postalCodeFrom,
            verificationCode: '',
            expirationString: this.expirationFrom || YEAR_PLACEHOLDER,
            expirationMoment: null,
            verificationMaxLength: 4,
            startDate: new Date(),
            consentCheck: false,
        };
    },

    computed: {
        ...mapState({
            paymentAccounts: ({ billing }) => billing?.paymentAccounts,
            countriesList: ({ global }) => global.countryOptions,
            regionOptions: ({ global }) => global.regionOptions,
            regionRequired: ({ sales }) => sales.appSalesInfo.regionRequired,
            paymentSubtype: ({ sales }) => sales.paymentSubtype,
            publicInvoice: ({ sales }) => sales.publicInvoice,
            appSalesInfo: ({ sales }) => sales.appSalesInfo,
        }),

        countryOptions() {
            return this.paymentSource === 'public-payment-method'
                ? this.countries
                : this.countriesList;
        },

        cardLogo() {
            if (!this.cardNumber) return null;

            const cardType = detectCardType(this.cardNumber);

            if (cardType && this.paymentSource !== 'public-payment-method') {
                return `${import.meta.env.BASE_URL}images/${getCardLogoPath(cardType)}`;
            }

            return null;
        },

        paymentSubType() {
            return Object.keys(this.paymentSubtype)?.length
                ? this.paymentSubtype
                : this.publicCheckoutForm?.paymentSubtype;
        },

        showRainforest() {
            return this.paymentSubType?.toLowerCase() === PAYMENT_ACCOUNT_TYPE.RAINFOREST.toLowerCase()
                || this.paymentAccounts?.find(({ isDefault, accountType }) => accountType === PAYMENT_ACCOUNT_TYPE.RAINFOREST && isDefault);
        },

        showStripeElements() {
            const isStripeConnected = this.paymentAccounts?.some(({ isDefault, accountType }) => accountType === PAYMENT_ACCOUNT_TYPE.STRIPE && isDefault);
            const isStripeProcessor = isStripeConnected || this.paymentSubType?.toLowerCase() === PAYMENT_ACCOUNT_TYPE.STRIPE.toLowerCase();
            const isStripeElementsEnabled = this.stripeElementsEnabled
            || this.publicInvoice?.featureFlags?.stripePaymentElementsEnabled
            || this.publicCheckoutForm?.featureFlags?.stripePaymentElementsEnabled;

            return isStripeElementsEnabled && isStripeProcessor;
        },

        formattedExpiration: {
            get() {
                return moment(this.expirationMoment).isValid()
                    ? this.expirationMoment.format('MM/YY')
                    : this.expirationString;
            },

            set: debounce(function(value) {
                this.checkForRequiredFields();
                this.formatExpiration(value);
                this.$nextTick(() => {
                    this.$refs.expirationField.input_reportValidity();
                });
            }, INPUT_DEBOUNCE_DELAY),
        },

        expiredCardDate() {
            if (!this.isExpirationValid) {
                return false;
            }

            const [month, year] = this.formattedExpiration.split('/').map(Number);
            const currentDate = new Date();
            const inputYear = 2000 + year;
            const inputDate = new Date(inputYear, month - 1);

            return moment(inputDate).isBefore(currentDate);
        },

        chargeStatementData() {
            return {
                name: this.displayFullName || this.paymentFrom,
                amount: this.$n(this.rate, 'currency'),
            };
        },

        isCreditCardValid() {
            const regex = RegExp(CREDIT_CARD_NUMBER_REGEX);

            return regex.test(this.cardNumber) && this.cardNumber !== '' && luhn.validate(this.cardNumber);
        },

        isExpirationValid() {
            const regex = RegExp(CREDIT_CARD_EXPIRATION_REGEX);

            return regex.test(this.formattedExpiration);
        },

        isVerificationCodeValid() {
            const regex = RegExp(CREDIT_CARD_CVV_REGEX);

            return regex.test(this.verificationCode);
        },

        isRegionRequired() {
            return Boolean((this.regionRequired || this.showFullAddress) && this.regionOptions.length);
        },

        showForm() {
            return Boolean(this.readonly || this.countryOptions?.length);
        },
    },

    created() {
        if (this.debounceDelay) {
            this.handleCardNumberInput = debounce(this.handleCardNumberInput, this.debounceDelay);
            this.validateExpirationDate = debounce(this.validateExpirationDate, this.debounceDelay);
            this.handleVerificationCodeInput = debounce(this.handleVerificationCodeInput, this.debounceDelay);
            this.handlePostalCodeInput = debounce(this.handlePostalCodeInput, this.debounceDelay);
        }

        if (!this.readonly && this.paymentSource !== 'public-payment-method') {
            this.loadCountryOptions();
        }
    },

    methods: {
        getData() {
            const data = {
                nameOnCard: this.nameOnCard,
                cardNumber: this.cardNumber,
                region: this.region ? this.region.label : null,
                country: this.country ? this.country.label : null,
                postalCode: this.postalCode,
                verificationCode: this.verificationCode,
                expirationMonth: this.expirationMoment ? this.expirationMoment.format(this.expirationMonthFormat) : '',
                expirationYear: this.expirationMoment ? this.expirationMoment.format(this.expirationYearFormat) : '',
            };

            if (this.showEmailField) {
                data.email = this.email;
            }

            if (this.isAddCreditCardFlow) {
                data.consent = this.consentCheck ? 'EXPLICIT_CONSENT' : 'NO_CONSENT';
            }

            if (this.showFullAddress) {
                data.address1 = this.address1;
                data.address2 = this.address2;
                data.locality = this.locality;
            }

            return data;
        },

        preventAlphanumeric(e) {
            const isNumberKey = /^[0-9]$/.test(e.key);
            const isAllowed = isNumberKey || ALLOWED_KEYS.includes(e.key);

            if (!isAllowed) e.preventDefault();
        },

        countryChanged() {
            this.handleFormInput('country');
            this.checkForRequiredFields();
            this.loadRegionOptions(this.country.value);
        },

        getRequiredFields() {
            const requiredFields = [
                {
                    name: 'nameOnCard',
                    isSatisfied: this.nameOnCard !== '' || (this.paymentFrom != null && this.paymentFrom !== ''),
                },
                {
                    name: 'cardNumber',
                    isSatisfied: this.cardNumber !== '',
                },
                {
                    name: 'expirationString',
                    isSatisfied: this.validateExpirationDate(),
                },
                {
                    name: 'verificationCode',
                    isSatisfied: this.verificationCode !== '',
                },
                {
                    name: 'postalCode',
                    isSatisfied: Boolean(this.postalCode),
                },
                {
                    name: 'country',
                    isSatisfied: Boolean(this.country) && this.country.value !== '',
                },
            ];

            if (this.showFullAddress) {
                requiredFields.push({
                    name: 'address1',
                    isSatisfied: this.address1 !== '',
                });
            }

            if (this.showFullAddress) {
                requiredFields.push({
                    name: 'locality',
                    isSatisfied: this.locality !== '',
                });
            }

            if (this.isRegionRequired) {
                requiredFields.push({
                    name: 'region',
                    isSatisfied: Boolean(this.region) && this.region.value !== '',
                });
            }

            return requiredFields;
        },

        validateExpirationDate() {
            if (this.expirationString === YEAR_PLACEHOLDER) {
                return false;
            }

            this.formatExpiration(this.expirationString);

            return this.expirationMoment !== null;
        },

        handleFormBlur(field) {
            this.emitFormEvent({ type: 'blur', field });
        },

        handleFormInput(field) {
            this.emitFormEvent({ type: 'input', field });
            this.checkForRequiredFields();
        },

        handleRegionInput(val) {
            if (val && val.value) {
                this.handleFormInput('region');
            }
        },

        checkForRequiredFields() {
            const requiredFields = this.getRequiredFields();
            const hasMissingRequiredFields = requiredFields.some(({ isSatisfied }) => isSatisfied !== true);

            return this.$emit('update:hasRequiredFields', !hasMissingRequiredFields);
        },

        handleCardNumberInput() {
            this.handleFormInput('cardNumber');
            this.checkForRequiredFields();
            this.$refs.cardNumberField.input_reportValidity();
        },

        handleVerificationCodeInput() {
            this.handleFormInput('verificationCode');
            this.checkForRequiredFields();
            this.$refs.verificationCodeField.input_reportValidity();
        },

        handlePostalCodeInput() {
            this.handleFormInput('postalCode');
        },

        expirationOnFocus() {
            if (this.expirationString === YEAR_PLACEHOLDER) {
                this.expirationString = '';
            }
        },

        expirationOnBlur() {
            if (this.expirationString === '') {
                this.expirationString = YEAR_PLACEHOLDER;
            }

            this.handleFormBlur('expiration');
        },

        formatExpiration(value) {
            let dateString = value;

            this.expirationString = dateString;

            dateString = dateString.replace(/\D/g, '');
            this.expirationString = dateString;

            if (dateString.length > 2) {
                dateString = dateString.length === 3 ? `0${dateString}` : dateString;

                this.expirationMoment = moment(dateString).isValid()
                    ? moment(dateString, 'MMYY')
                    : dateString;
            } else {
                this.expirationMoment = null;
            }
        },

        async loadCountryOptions() {
            const message = this.$t('CreditCardFields.errors.loadCountryOptions');

            try {
                await this.$store.dispatch(this.loadCountryDispatch);

                this.setLocalCountry();
            } catch (error) {
                this.$error({ message });
            }
        },

        async loadRegionOptions(countryCode) {
            if (this.paymentSource === 'public-payment-method') return;

            await this.$store.dispatch(this.loadRegionDispatch, { countryCode });

            this.region = null;
            this.checkForRequiredFields();
        },

        setLocalCountry() {
            const found = this.countryOptions.find(({ value }) => {
                return value === this.companyProfile.country;
            });

            if (found) {
                this.country = found;
                this.loadRegionOptions(this.country.value);
            }
        },

        emitFormEvent(eventObj) {
            this.$emit('form-event', eventObj);
        },
    },
};
</script>

<style lang="scss">
    .fs-block {
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            margin: 0;
        }
    }
</style>

<style lang="scss" scoped>
    .credit-card-field {
        flex: 2;
    }

    .credit-card-fields {
        display: flex;
        flex-direction: column;

        &.is-stripe-elements {
            margin-bottom: $spacing-200;
        }
    }
    .explicit-checkbox {
        @include padding-start(0);
    }

    .card-logo {
        width: 24px;
        margin-top: $spacing-050;
    }

    .cvv {
        @media($small) {
            --input-margin-bottom: 0;
        }
    }
</style>
